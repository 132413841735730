import cn from "classnames";
import { Rating } from "../review/Rating";
import { SimpleContentParser } from "../SimpleContentParser";
import styles from "./UserFeedbackCard.module.scss";
import { IconImage } from "@/components/UI/IconImage";
import sc from "@/styles/modules/card.module.scss";

export type UserFeedbackCardProps = {
  className?: string;
  background?: boolean;
  id: number | string;
  rating: number;
  date: string;
  text: string;
};

export const UserFeedbackCard = ({
  id,
  rating,
  date,
  text,
  background,
  className,
}: UserFeedbackCardProps) => {
  return (
    <div
      className={cn(
        sc.cardGlassHover,
        styles.card,
        {
          [styles.cardBackground]: background,
        },
        className
      )}
    >
      <div className={styles.top}>
        <IconImage size={54} icon="graduation-cap" className={styles.icon} />
        <div className={styles.heading}>
          <div className={styles.title}>{id}</div>
          <div className={styles.headingBottom}>
            <Rating rating={rating} className={styles.rating} />
            <div className={styles.date}>{date}</div>
          </div>
        </div>
      </div>
      <SimpleContentParser content={text} className={styles.text} />
    </div>
  );
};
