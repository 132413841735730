"use client";
import cn from "classnames";
import { upperFirst } from "lodash";
import { useEffect, useRef, useState } from "react";
import styles from "./AccordionItem.module.scss";
import { SimpleContentParser } from "./SimpleContentParser";
import { SpriteIcon } from "@/components/UI/SpriteIcon";
import IconAngleDown from "public/img/icons/svg/angle-down.svg";
import cs from "@/styles/modules/card.module.scss";

export type AccordionItemProps = {
  heading: React.ReactNode;
  children?: React.ReactNode;
  active?: boolean;
  onToggle?: (isOpen: boolean) => void;
  className?: string;
  background?: boolean;
  type?: "default" | "small";
  microData?: boolean;
  content?: string;
  headingSize?: "default" | "big";
};

export const AccordionItem = ({
  heading,
  children,
  active,
  onToggle,
  className,
  background,
  type = "default",
  content,
  microData = false,
  headingSize,
}: AccordionItemProps) => {
  const contentWrapper = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(!!active);

  const openItem = () => {
    if (!contentWrapper.current) return;
    contentWrapper.current.style.maxHeight = contentWrapper.current.scrollHeight + "px";
    setIsOpen(true);
  };

  const closeItem = () => {
    if (!contentWrapper.current) return;
    contentWrapper.current.style.maxHeight = "0";
    setIsOpen(false);
  };

  const toggleItem = () => {
    if (!isOpen) openItem();
    else closeItem();
  };

  useEffect(() => {
    isOpen && openItem();
    onToggle && onToggle(isOpen);
  }, [isOpen, onToggle]);

  const itemMicroData = microData
    ? {
        itemScope: true,
        itemProp: "mainEntity",
        itemType: "https://schema.org/Question",
      }
    : {};

  const contentMicroData = microData
    ? {
        itemScope: true,
        itemProp: "acceptedAnswer",
        itemType: "https://schema.org/Answer",
      }
    : {};

  const headingMicroData = microData
    ? {
        itemProp: "name",
      }
    : {};

  const textMicroData = microData
    ? {
        itemProp: "text",
      }
    : {};

  return (
    <div
      className={cn(
        styles.accordionItem,
        {
          [cs.cardGlassHover]: !background,
          [cs.cardDefaultHover]: !!background,
          [styles.open]: isOpen,
          [styles.withBg]: background,
          [styles.headingBig]: headingSize === "big",
        },
        styles[`type${upperFirst(type)}`],
        className
      )}
      {...itemMicroData}
    >
      <div className={cn(styles.headWrapper)} onClick={toggleItem}>
        {typeof heading === "string" ? (
          <h3 className={styles.heading} {...headingMicroData}>
            {heading}
          </h3>
        ) : (
          heading
        )}
        <button aria-label="expand / shrink">
          <SpriteIcon icon={IconAngleDown} />
        </button>
      </div>
      <div ref={contentWrapper} className={styles.contentWrapper} {...contentMicroData}>
        {content ? (
          <SimpleContentParser content={content} className={styles.content} {...textMicroData} />
        ) : (
          <div className={styles.content} {...textMicroData}>
            {children}
          </div>
        )}
      </div>
    </div>
  );
};
