export const svgSpriteIcons = [
  "underline",
  "hunting",
  "list-ul",
  "arrow-down",
  "clock-eight",
  "angle-right-b",
  "paraphrasing-tool",
  "file-download-alt",
  "grammar",
  "thesis-statement-generator",
  "user",
  "circle",
  "grin-tongue-wink",
  "quote-right",
  "equal-circle",
  "receipt-alt",
  "pricetag-alt",
  "essay-maker",
  "calculator-alt",
  "file-medical-alt",
  "fb",
  "arrow-circle-up",
  "align-justify",
  "usd-circle",
  "books",
  "database",
  "angle-down",
  "grade-calculator",
  "paper grader",
  "cloud-wifi",
  "angle-up",
  "exclamation-triangle",
  "alphabetizer",
  "lock",
  "cloud-upload",
  "fire",
  "piggy-bank",
  "calender",
  "pen",
  "clipboard",
  "instagram",
  "link",
  "sad",
  "file-lock-alt",
  "arrow-right",
  "location-point",
  "at",
  "percentage",
  "case-converter",
  "number",
  "stopwatch",
  "summarizer",
  "envelope",
  "document-layout-left",
  "map-location",
  "desktop",
  "file-contract-dollar",
  "bolt",
  "spelling-checker",
  "wallet",
  "download-alt-1",
  "flag",
  "mobile-vibrate",
  "spinner-loading",
  "support",
  "grammar-checker",
  "edit-alt",
  "bold",
  "paper",
  "trophy",
  "plus",
  "check",
  "comment-info",
  "angle-left-b",
  "comment-alt-message",
  "angle-right",
  "search-alt",
  "info-circle",
  "copy",
  "burger",
  "file-download",
  "clipboard-alt",
  "title-page-generator",
  "align-right",
  "check-circle",
  "save",
  "filter-slash",
  "favorite",
  "calendar-alt",
  "setting",
  "paperclip",
  "file-alt",
  "message",
  "left-to-right-text-direction",
  "award",
  "thumbs-up",
  "file-redo-alt",
  "search-alt 2",
  "words-to-pages",
  "file-check",
  "bill",
  "files-landscapes-alt",
  "lock-alt",
  "facebook",
  "convert",
  "redo",
  "assignment-generator",
  "comment-question",
  "arrow-up-right",
  "file-edit-alt",
  "file-check-alt",
  "exclamation-circle",
  "book-reader",
  "text-fields",
  "trash",
  "bag-alt",
  "graduation-cap",
  "key-skeleton",
  "edit",
  "unlink",
  "essay-title-generator",
  "credit-card",
  "chat-question",
  "conclusion-generator",
  "analytics",
  "window-close",
  "download-alt",
  "diamond",
  "phone",
  "readability-checker",
  "sync",
  "align-center-v",
  "eye",
  "book-medical",
  "essay-typer",
  "gift",
  "word-counter",
  "arrow-up",
  "money",
  "grin",
  "live-chat",
  "wrap-text",
  "file-shield-alt",
  "filter",
  "italic",
  "insta",
  "plagiarism-chekcer",
  "mobile-android-alt",
  "arrow-left",
  "align-center",
  "university",
  "clock-two",
  "headphones-alt",
  "external-link-alt",
  "bookmark",
  "rocket",
  "tablet",
  "linkdin",
  "list-ol",
  "cloud-download",
  "diary",
  "angle-left",
  "shopping-cart",
  "shield-check",
  "users-alt",
  "minus",
  "all-pencil-pen",
  "hint bulb",
  "times",
  "print",
  "align-left",
  "font",
] as const;

export type SVGSpriteIconType = typeof svgSpriteIcons[number];
