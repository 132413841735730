"use client";
import cn from "classnames";
import { upperFirst } from "lodash";
import type { AllHTMLAttributes, SVGProps } from "react";
// import { Icon } from "./Icon";
import type { SVGSpriteIconType } from "./SVGSpriteIconType";
import styles from "./WrappedIcon.module.scss";
import { SpriteIcon } from "@/components/UI/SpriteIcon";
import { allIcons, IconImageType } from "@/types/icon";
import { IconImage } from "@/components/UI/IconImage";
import Image from "next/image";
import { svgSpriteIcons } from "./SVGSpriteIconType";
import { useCallback, useEffect, useState } from "react";
import { LazySvgSpriteIcon } from "@/components/UI/LazySvgSpriteIcon";

export type WrappedIconProps = {
  icon?: SVGProps<SVGSVGElement> | IconImageType | SVGSpriteIconType | string;
  className?: string;
  shape?: "square" | "circle";
  type?:
    | "default"
    | "white"
    | "primary"
    | "primaryLight"
    | "green"
    | "greenLight"
    | "yellow"
    | "red"
    | "whiteTransparent";
  size?: "default" | "small" | "middle";
  text?: React.ReactNode;
  number?: string | number;
  withIndex?: boolean;
  svg?: boolean;
} & Omit<AllHTMLAttributes<HTMLSpanElement>, "size">;

export const WrappedIcon = ({
  icon,
  type = "default",
  shape = "square",
  size = "default",
  className,
  text,
  number,
  svg,
  ...props
}: WrappedIconProps) => (
  <span
    className={cn(
      styles.wrapper,
      styles[`type${upperFirst(type)}`],
      styles[`shape${upperFirst(shape)}`],
      styles[`size${upperFirst(size)}`],
      {
        [styles.withText]: !!text,
        [styles.withNumber]: !!number,
      },
      className
    )}
    {...props}
  >
    {number && <span className={styles.number}>{number}</span>}
    {icon &&
      (typeof icon === "string" ? (
        allIcons.includes(icon as IconImageType) && !svg ? (
          <IconImage size={36} icon={icon as IconImageType} />
        ) : svgSpriteIcons.includes(icon as SVGSpriteIconType) ? (
          <LazySvgSpriteIcon name={icon} />
        ) : (
          (icon.startsWith("/") || icon.startsWith("http:") || icon.startsWith("https:")) && (
            <Image width={36} height={36} src={icon} alt="Illustration" quality={100} />
          )
        )
      ) : (
        <SpriteIcon icon={icon} />
      ))}
    {text && text}
    {type === "primary" && shape === "circle" && <span className={styles.addShadow} />}
  </span>
);
