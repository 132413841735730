"use client";
import { Container } from "components/layout/Container";
import { Heading } from "components/layout/Heading";
import { ListItem } from "components/typography/ListItem";
import { Button } from "components/UI/Buttons/Button";
import { useState } from "react";
import styles from "./SectionTabList.module.scss";
import cn from "classnames";
import { TabButton } from "components/UI/Buttons/TabButton";
import { WrappedIcon } from "components/UI/WrappedIcon";
import { IconImage } from "@/components/UI/IconImage";

export type SectionTabListProps = {
  heading: string;
  subheading: string;
  columns?: number;
  tabs: {
    heading?: string;
    items: {
      name: string;
      listColumns?: number;
      fullWidth?: boolean;
      list:
        | string[]
        | {
            title: string;
            href?: string;
          }[];
    }[];
  };
  button?: {
    text: string;
    href: string;
  };
};

export const SectionTabList = ({ heading, subheading, tabs, button, columns = 3 }: SectionTabListProps) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  return (
    <section className={cn(styles.section, styles[`columns${columns}`])}>
      <Container>
        <Heading title={heading} subheading={subheading} className={styles.heading} />
        {tabs.heading && <p className={styles.tabsTitle}>{tabs.heading}</p>}
        <div className={styles.tabs}>
          <div className={styles.tabButtons}>
            <div className={styles.tabButtonsInner}>
              {tabs.items.map((item, index) => (
                <TabButton
                  key={index}
                  onClick={() => setActiveTab(index)}
                  active={index === activeTab}
                  text={item.name}
                />
              ))}
            </div>
          </div>
          <div className={styles.tabContents}>
            {tabs.items.map((item, index) => (
              <ul
                key={index}
                className={cn(styles.list, {
                  [styles.listActive]: index === activeTab,
                  [styles.listCol3]: item.listColumns === 3,
                  [styles.listCol4]: item.listColumns === 4,
                  [styles.listFullWidth]: item.fullWidth,
                })}
              >
                {item.list.map((listItem, itemIndex) =>
                  typeof listItem === "string" ? (
                    <ListItem key={itemIndex} className={styles.listItem} type="bullet">
                      {listItem}
                    </ListItem>
                  ) : (
                    <li key={itemIndex} className={styles.downloadListItem}>
                      {listItem.href ? (
                        <a href={listItem.href} download rel="nofollow">
                          <IconImage size={32} icon="download" className={styles.downloadIcon} />
                          {listItem.title}
                        </a>
                      ) : (
                        <span>{listItem.title}</span>
                      )}
                    </li>
                  )
                )}
              </ul>
            ))}
          </div>
        </div>
        {button && (
          <div className={styles.btnWrap}>
            <Button href={button.href} className={styles.button}>
              {button.text}
            </Button>
          </div>
        )}
      </Container>
    </section>
  );
};

export default SectionTabList;
