export type IconImageType = IconImageGeneral | IconImageService | IconImageTool;

const generalImageIcons = [
  "redo",
  "admission-essay-writing-service",
  "alarm-clock",
  "all-tools",
  "alphabetizer",
  "article",
  "assignment-calendar",
  "blue-circle",
  "book-report-writing-service",
  "books",
  "briefcase",
  "bulb",
  "buy-essay",
  "calculator",
  "calendar-alt",
  "capstone-project-writing-service",
  "case-converter",
  "case-study-writing-service",
  "check-round",
  "check",
  "classical-building",
  "clipboard-alt",
  "clock",
  "college-essay-writing-service",
  "comment-alt-message",
  "conclusion-generator",
  "copy",
  "coursework-writing-service",
  "cross-1",
  "cross",
  "desktop",
  "dissertation-writing-service",
  "do-my-homework",
  "dollar-sign",
  "download",
  "edit-alt",
  "edit",
  "email",
  "envelope-failed",
  "envelope-success",
  "envelope",
  "essay-maker",
  "essay-title-generator",
  "essay-typer",
  "extra-feat",
  "eyes",
  "face-with-monocle",
  "faq",
  "file-alt",
  "file-check-alt",
  "file-check",
  "file-contract-dollar",
  "file-download",
  "file-edit-alt",
  "file-pen",
  "file-shield-alt",
  "file",
  "files-landscapes-alt",
  "files",
  "gear",
  "gem-stone",
  "gift",
  "globe-showing-americas",
  "grade-calculator",
  "graduation-cap",
  "grammar-checker",
  "grammar",
  "green-circle",
  "grin-tongue-wink",
  "hand-pointing",
  "headphones",
  "hourglass-done",
  "infinity",
  "key-skeleton",
  "left-arrow",
  "link",
  "literature-review-writing-service",
  "lock-alt",
  "lock",
  "magnifying-glass",
  "medal",
  "money-bag",
  "nerd-face",
  "newspaper",
  "notepad",
  "order-essay",
  "paper-grader",
  "paper",
  "paraphrasing-tool",
  "pause-button",
  "pen",
  "percentage",
  "personal-statement-writing-service",
  "piggy-bank",
  "plagiarism-checker",
  "plagiarism-chekcer",
  "play-button",
  "presentation-writing-service",
  "printer",
  "readability-checker",
  "receipt-alt",
  "red-circle",
  "research-paper-writing-service",
  "right-arrow-curving-up",
  "right-arrow",
  "rocket",
  "scholarship-essay-writing",
  "search-alt",
  "share",
  "shield-check",
  "shopping-cart",
  "slightly-frowning-face",
  "sparkles",
  "speech-writing-service",
  "spell-checker",
  "star",
  "stopwatch",
  "studycrumb",
  "summarizer",
  "sync",
  "teacher",
  "tear-off-calendar",
  "telephone-receiver",
  "term-paper-writing-service",
  "thesis-statement-generator",
  "thesis-writing-service",
  "thumbs-down",
  "thumbs-up",
  "thunder",
  "title-page-generator",
  "trophy",
  "up-right-arrow",
  "updated",
  "user",
  "users-alt",
  "warning",
  "watch",
  "winking-face",
  "word-counter",
  "words-to-pages-converter",
  "words-to-pages",
  "wrap-text",
  "write-my-assignment",
  "write-my-lab-report",
  "writing-content",
  "writing-hand",
  "yellow-circle",
] as const;

export type IconImageGeneral = typeof generalImageIcons[number];

const serviceImageIcons = [
  "admission-essay-writing-service",
  "book-report-writing-service",
  "buy-essay",
  "capstone-project-writing-service",
  "case-study-writing-service",
  "college-essay-writing-service",
  "coursework-writing-service",
  "dissertation-writing-service",
  "do-my-homework",
  "literature-review-writing-service",
  "order-essay",
  "personal-statement-writing-service",
  "presentation-writing-service",
  "research-paper-writing-service",
  "scholarship-essay-writing",
  "speech-writing-service",
  "term-paper-writing-service",
  "thesis-writing-service",
  "write-my-assignment",
  "write-my-lab-report",
] as const;

export type IconImageService = typeof serviceImageIcons[number];

const toolImageIcons = [
  "all-tools",
  "alphabetizer",
  "assignment-calendar",
  "case-converter",
  "conclusion-generator",
  "essay-maker",
  "essay-title-generator",
  "essay-typer",
  "grade-calculator",
  "grammar-checker",
  "paper-grader",
  "paraphrasing-tool",
  "plagiarism-checker",
  "readability-checker",
  "spell-checker",
  "summarizer",
  "thesis-statement-generator",
  "title-page-generator",
  "word-counter",
  "words-to-pages-converter",
  // new
  "essay-shortener",
  "essay-extender",
  "essay-introduction-generator",
  "hook-generator",
  "outline-generator",
  "paragraph-generator",
  "passive-to-active-voice-converter",
  "read-my-paper",
  "text-simplifier",
  "discussion-post",
] as const;

export type IconImageTool = typeof toolImageIcons[number];

export const allIcons = [...generalImageIcons, ...serviceImageIcons, ...toolImageIcons];
