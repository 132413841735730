import { useRef, useState, useEffect, useCallback, ReactNode } from "react";
import { debounce } from "lodash";
import cn from "classnames";

import styles from "./Dropdown.module.scss";

export type DropdownProps = {
  className?: string;
  ItemComponent: React.ComponentType<{
    item: {
      id: number | string;
      name: string | number | ReactNode;
    };
    onClick: () => void;
    itemProps: Record<string, any>;
  }>;
  ButtonComponent: React.ComponentType<{
    onClick: () => void;
    buttonProps: Record<string, any>;
  }>;
  buttonProps?: Record<string, any>;
  itemProps?: Record<string, any>;
  items: {
    id: number | string;
    name: string | number | ReactNode;
  }[];
};

export const Dropdown = ({
  ItemComponent,
  ButtonComponent,
  buttonProps = {},
  itemProps = {},
  items,
  className,
}: DropdownProps) => {
  const [dropdownItems, setDropdownItems] = useState(items);
  const [open, setOpen] = useState(false);
  const [below, setBelow] = useState(true);
  const dropdownBody = useRef<HTMLDivElement>(null);
  const dropdownBtn = useRef<HTMLDivElement>(null);

  const closeDropdown = () => {
    setOpen(false);
    setBelow(true);
    if (!dropdownBody.current) return;
    dropdownBody.current.scrollTop = 0;
  };

  const openDropdown = () => {
    if (!dropdownBody.current) return;
    setOpen(true);
    const selectedElem = dropdownBody.current.querySelector("." + styles.linkSelected);
    if (!selectedElem) return;
    setTimeout(() => {
      if (!dropdownBody.current) return;
      const diff =
        dropdownBody.current.getBoundingClientRect().top -
        selectedElem.getBoundingClientRect().top -
        dropdownBody.current.scrollTop +
        5;
      dropdownBody.current.scrollTop = -1 * diff;
    }, 0);
  };

  const onClick = () => {
    open ? closeDropdown() : openDropdown();
  };

  const checkDropdownPosition = () => {
    if (!dropdownBtn.current) return;
    if (!dropdownBody.current) return;
    const winTop = window.scrollY;
    const elHeight = dropdownBtn.current.clientHeight + dropdownBody.current.clientHeight;
    const elTop = winTop + dropdownBtn.current.getBoundingClientRect().top;

    const result =
      elTop + elHeight <= winTop + window.innerHeight && elTop + elHeight < document.body.clientHeight;

    setBelow(result);
  };

  const debouncedCheckDropdownPosition = debounce(checkDropdownPosition, 100);

  const onDocClick = useCallback((event: MouseEvent) => {
    if (dropdownBtn.current === event.target || dropdownBtn.current?.contains(event.target as Node)) return;
    if (dropdownBtn.current && !dropdownBody.current?.contains(event.target as Node)) closeDropdown();
  }, []);

  useEffect(checkDropdownPosition, [open, dropdownBody]);

  useEffect(() => {
    document.addEventListener("click", onDocClick);
    window.addEventListener("scroll", debouncedCheckDropdownPosition);
    window.addEventListener("resize", debouncedCheckDropdownPosition);

    return () => {
      document.removeEventListener("click", onDocClick);
      window.removeEventListener("scroll", debouncedCheckDropdownPosition);
      window.removeEventListener("resize", debouncedCheckDropdownPosition);
    };
  }, [debouncedCheckDropdownPosition, onDocClick]);

  useEffect(() => {
    setDropdownItems(items);
  }, [items]);

  return (
    <div
      className={cn(styles.dropdownResponsive, className, {
        [styles.dropdownResponsiveOpen]: open,
        [styles.dropdownResponsiveOpenBelow]: open && below,
        [styles.dropdownResponsiveOpenAbove]: open && !below,
      })}
    >
      <div ref={dropdownBtn}>
        <ButtonComponent onClick={onClick} buttonProps={{ ...buttonProps, items: dropdownItems, open }} />
      </div>
      <div
        ref={dropdownBody}
        className={cn(styles.itemsWrap, {
          [styles.itemsWrapOpen]: open,
        })}
      >
        <ul className={styles.itemsList}>
          {dropdownItems.map((item, i) => (
            <li key={i} className={styles.item}>
              <ItemComponent
                item={item}
                onClick={closeDropdown}
                itemProps={{ ...itemProps, items: dropdownItems }}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
