import { Button } from "components/UI/Buttons/Button";
import s from "./TabButton.module.scss";
import Image from "next/image";
import cn from "classnames";
import IconAngleDown from "public/img/icons/svg/angle-down.svg";
import { SpriteIcon } from "@/components/UI/SpriteIcon";

type TabButtonProps = {
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  text?: string;
  href?: string;
  small?: boolean;
  dropdown?: boolean;
  image?: {
    src: string;
    alt: string;
    width: number;
    height: number;
  };
};

export const TabButton = ({
  image,
  href,
  text,
  active,
  onClick,
  disabled,
  small,
  dropdown,
}: TabButtonProps) => (
  <Button
    href={href}
    onClick={onClick}
    type={active ? "primary" : "white"}
    outline
    className={cn(s.tabButton, {
      [s.small]: small,
      [s.dropdown]: dropdown,
      [s.active]: active,
    })}
    disabled={disabled}
  >
    <span className={s.tabButtonInner}>
      {image && <Image {...image} quality={100} className={s.image} />}
      {text && <span className={s.tabButtonText}>{text}</span>}
    </span>
    {dropdown && <SpriteIcon icon={IconAngleDown} className={s.dropdownIcon} />}
  </Button>
);
