"use client";

import { useState } from "react";
import cn from "classnames";
import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./ReviewsSlider.module.scss";
import SwiperCore, { Pagination } from "swiper";
import { UserFeedbackCard } from "components/shared/cards/UserFeedbackCard";
import { TabButton } from "components/UI/Buttons/TabButton";
import { SliderPagination } from "./SliderPagination";
import type { ReviewType } from "components/shared/review/ReviewItem";
import { useInteraction } from "@/helpers/useInteraction";
import { DropdownSelect } from "@/components/UI/Dropdowns/DropdownSelect";
import Image from "next/image";

// @ts-ignore
SwiperCore.use(Pagination);

type ReviewCard = {
  id: number | string;
  rating: number;
  date: string;
  text: string;
};

export type ReviewsType = Record<ReviewType, ReviewCard[]>;

export type ReviewsSliderProps = {
  reviews: ReviewsType;
};

const reviewsIcons = {
  studycrumb: {
    name: "StudyCrumb",
    width: 29,
    height: 28,
    src: "studycrumb-rev.svg",
    srcMob: "studycrumb-rev.svg",
  },
  trustpilot: {
    name: "Trustpilot",
    width: 82,
    height: 20,
    src: "trustpilot-full.svg",
    srcMob: "trustpilot-mob.svg",
  },
  reviews: {
    name: "Reviews.io",
    width: 103,
    height: 16,
    src: "reviews-io-full.png",
    srcMob: "reviews-io-mob.svg",
  },
  sitejabber: {
    name: "Sitejabber",
    width: 96,
    height: 24,
    src: "sitejabber-full.png",
    srcMob: "sitejabber-mob.svg",
  },
};

type SingleSliderProps = {
  active?: boolean;
  slug: ReviewType;
  items: ReviewCard[];
};

const SingleSlider = ({ active, slug, items }: SingleSliderProps) => {
  const [swiper, setSwiper] = useState<SwiperCore>();

  const { isInteracted } = useInteraction();

  return (
    <div
      className={cn(styles.sliderWrap, {
        [styles.sliderWrapActive]: active,
      })}
    >
      <div className={styles.sliderInner}>
        {!isInteracted && (
          <div className={styles.mocked}>
            {items.map((item, index) => (
              <div key={index}>
                <UserFeedbackCard {...item} className={styles.card} />
              </div>
            ))}
          </div>
        )}

        {isInteracted && (
          <Swiper
            spaceBetween={15}
            slidesPerView={1}
            loopAdditionalSlides={4}
            loop={true}
            centeredSlides
            onSwiper={setSwiper}
            pagination={{
              el: `.reviews-slider-${slug}-pagination`,
              clickable: true,
            }}
            className={styles.slider}
            breakpoints={{
              576: {
                spaceBetween: 30,
              },
            }}
          >
            {items.map((item, index) => (
              <SwiperSlide key={index}>
                <UserFeedbackCard {...item} className={styles.card} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
      <SliderPagination
        onNextClick={() => {
          swiper?.slideNext();
        }}
        onPrevClick={() => {
          swiper?.slidePrev();
        }}
        color="white"
        paginationClassName={`reviews-slider-${slug}-pagination`}
      />
    </div>
  );
};

export const ReviewsSlider = ({ reviews }: ReviewsSliderProps) => {
  const [activeTab, setActiveTab] = useState<ReviewType>("studycrumb");

  const validReviews = Object.entries(reviews).filter(([, value]) => !!value.length);

  if (!validReviews.length) return null;

  return (
    <div className={styles.reviewsTabs}>
      <DropdownSelect
        selectedValue={activeTab}
        items={Object.entries(reviewsIcons).map(([key, { width, height, name, srcMob }]) => ({
          id: key,
          name: (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
              }}
            >
              <Image
                src={`/img/misc/reviews/${srcMob}`}
                alt={key}
                width={key === "studycrumb" ? 29 : 24}
                height={key === "studycrumb" ? 28 : 24}
              />
              <strong
                style={
                  key === "trustpilot"
                    ? {
                        position: "relative",
                        top: 2,
                      }
                    : undefined
                }
              >
                {name}
              </strong>
            </span>
          ),
        }))}
        className={styles.reviewsSelect}
        onChange={(key) => setActiveTab(key as any)}
      />
      {validReviews.length > 1 && (
        <div className={styles.tabButtons}>
          <div className={styles.tabButtonsInner}>
            {Object.entries(reviews).map(([key, value]) => {
              const isEmpty = !value.length;

              if (isEmpty) return;

              const slug = key as ReviewType;
              const onClick = () => !isEmpty && setActiveTab(slug);
              return (
                <TabButton
                  key={key}
                  onClick={onClick}
                  active={key === activeTab}
                  text={key === "studycrumb" ? "StudyCrumb" : undefined}
                  image={{
                    src: `/img/misc/reviews/${reviewsIcons[slug].src}`,
                    alt: key,
                    width: reviewsIcons[slug].width,
                    height: reviewsIcons[slug].height,
                  }}
                  disabled={isEmpty}
                />
              );
            })}
          </div>
        </div>
      )}
      <div className={styles.slidersWrap}>
        {Object.entries(reviews).map(
          ([key, items]) =>
            !!items.length && (
              <SingleSlider key={key} slug={key as ReviewType} active={key === activeTab} items={items} />
            )
        )}
      </div>
    </div>
  );
};
