"use client";

import { Container } from "components/layout/Container";
import { Heading } from "components/layout/Heading";
import s from "./SectionOrder.module.scss";
import { Button } from "components/UI/Buttons/Button";
import { baseUrl, orderFormUrl } from "@/const";
import { useOrderForm, useOrderFormInitialSetup } from "@/lib/priceCalculator";
import Image from "next/image";

export type SectionOrderProps = {
  heading: string;
  subheading?: string;
  button?: {
    text: string;
    href: string;
  };
};

export const SectionOrder = ({ heading, subheading, button }: SectionOrderProps) => {
  const { config, formValues, defaults } = useOrderFormInitialSetup(baseUrl, orderFormUrl);

  const { submit } = useOrderForm(config, formValues, defaults, baseUrl);

  return (
    <section className={s.section}>
      <div className={s.bg}>
        <Image src="/img/section-order-grad.jpg" width={2540} height={1130} alt="Background" />
      </div>

      <Container className={s.container}>
        <Heading
          image="/img/icons/60/thunder.png"
          title={heading}
          titleLevel={2}
          titleTag={2}
          subheading={subheading}
          className={s.heading}
        />

        {button && (
          <div className={s.btnWrap}>
            <Button onClick={() => submit()}>{button.text}</Button>
          </div>
        )}
      </Container>
    </section>
  );
};

export default SectionOrder;
