import s from "./ListItem.module.scss";
import cn from "classnames";
import { upperFirst } from "lodash";
import { IconImage } from "@/components/UI/IconImage";

type ListProps = {
  children: React.ReactNode;
  className?: string;
  type: "bullet" | "rectangle" | "ordered" | "icon" | "check" | "ban" | "feature";
};

export const ListItem = ({ children, className, type }: ListProps) => {
  return (
    <li className={cn(s.item, s[`type${upperFirst(type)}`], className)}>
      <div className={s.inner}>
        {type === "check" && <IconImage size={32} icon="check-round" className={s.icon} />}
        {children}
      </div>
    </li>
  );
};
