import { useState, useEffect, useRef, ReactNode } from "react";
import { Dropdown } from "./Dropdown";
import classNames from "classnames";
import styles from "./Dropdown.module.scss";
import { SpriteIcon } from "@/components/UI/SpriteIcon";
import IconAngleDown from "public/img/icons/svg/angle-down.svg";

const ButtonComponent = ({ onClick, buttonProps }: any) => {
  const { error, items, value, placeholder = "" } = buttonProps;

  const hasSelectedValue = value !== null;

  return (
    <button
      type="button"
      className={classNames(styles.toggleBtn, styles.toggleBtnSelect, {
        [styles.toggleBtnError]: error,
        [styles.toggleBtnPlaceholder]: !hasSelectedValue,
      })}
      onClick={onClick}
    >
      {hasSelectedValue ? items.find((item: any) => item.id === value)?.name : placeholder}
      <SpriteIcon icon={IconAngleDown} className={styles.toggleBtnIcon} />
    </button>
  );
};

const ItemComponent = ({ item, onClick, itemProps }: any) => {
  const { value, setValue } = itemProps;

  const allProps = {
    type: "button" as const,
    className: classNames(styles.link, {
      [styles.linkSelected]: value === item.id,
    }),
    onClick: () => {
      onClick();
      setValue(item.id);
    },
  };

  return typeof item.name === "string" ? (
    <button {...allProps} dangerouslySetInnerHTML={{ __html: item.name }} />
  ) : (
    <button {...allProps}>{item.name}</button>
  );
};

export type DropdownSelectProps = {
  className?: string;
  error?: boolean;
  items: {
    id: string | number;
    name: string | number | ReactNode;
  }[];
  placeholder?: string;
  selectedFirstOption?: boolean;
  selectedValue?: number | string | null;
  onChange: (value?: string | number | null) => void;
  forceUpdate?: boolean;
  useForceUpdate?: boolean;
};
export const DropdownSelect = ({
  items,
  placeholder,
  selectedFirstOption = true,
  selectedValue = null,
  className,
  error,
  onChange,
  forceUpdate = false,
  useForceUpdate = false,
}: DropdownSelectProps) => {
  const [value, setValue] = useState(selectedFirstOption ? items && items?.[0]?.id : null);
  const onChangeRef = useRef<(value?: number | string | null) => void>();
  onChangeRef.current = onChange;

  useEffect(() => {
    if (!useForceUpdate) return;
    setValue(selectedValue);
  }, [selectedValue, setValue, forceUpdate, useForceUpdate]);

  useEffect(() => {
    if (!onChangeRef.current) return;
    onChangeRef.current(value);
  }, [value, onChangeRef]);

  return (
    <Dropdown
      className={classNames(styles.dropdownResponsiveSelect, className, {
        [styles.dropdownResponsiveError]: error,
      })}
      items={items}
      ButtonComponent={ButtonComponent}
      ItemComponent={ItemComponent}
      buttonProps={{ error, value, placeholder }}
      itemProps={{ value, setValue }}
    />
  );
};
