"use client";
import s from "./EditorContent.module.scss";
import cn from "classnames";
import {
  EditorContentData,
  EditorContentDataComponentsMap,
} from "@/components/pages/article/EditorContent/types";
import { defaultRenderers } from "@/components/pages/article/EditorContent/defaultRenderers";
import { Fragment } from "react";
import { HighlightCard } from "@/components/typography/HighlightCard";

type EditorContentProps = {
  data?: EditorContentData;
  renderers?: Partial<EditorContentDataComponentsMap>;
  slug?: string;
  className?: string;
};

export const EditorContent = ({ data, renderers = {}, slug = "", className = "" }: EditorContentProps) => {
  const availableRenderers: EditorContentDataComponentsMap = {
    ...defaultRenderers,
    ...renderers,
  };

  if (!data) return null;

  const hasBlockId = data.version.includes("2.21");

  return (
    <div className={cn(s.wrapper, className)}>
      {data.blocks
        .filter(({ type }) => type in availableRenderers)
        .map(({ id, data, tunes, type, ...rest }, i) => {
          const alignment = tunes?.alignmentTune?.alignment;
          const Component = availableRenderers[type];

          const Wrapper = tunes?.highlightCardTune?.isHighlightCard ? HighlightCard : Fragment;

          return (
            <Wrapper key={hasBlockId && id ? id : i}>
              <Component
                key={hasBlockId && id ? id : i}
                data={data as any}
                tunes={tunes}
                slug={slug}
                className={alignment ? s[alignment] : s.left}
                {...rest}
              />
            </Wrapper>
          );
        })}
    </div>
  );
};

export default EditorContent;
