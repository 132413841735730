import { EditorContentDataComponentsMap } from "@/components/pages/article/EditorContent/types";
import dynamic from "next/dynamic";

export const defaultRenderers: EditorContentDataComponentsMap = {
  code: dynamic(() => import("editorjs-blocks-react-renderer/dist/renderers/code")),
  // @ts-ignore
  delimiter: dynamic(() => import("editorjs-blocks-react-renderer/dist/renderers/delimiter")),
  embed: dynamic(() => import("editorjs-blocks-react-renderer/dist/renderers/embed")),
  header: dynamic(() => import("editorjs-blocks-react-renderer/dist/renderers/header")),
  image: dynamic(() => import("@/components/pages/article/EditorContent/renderers/ImageBlock")),
  list: dynamic(() => import("@/components/pages/article/EditorContent/renderers/EditorContentStyledList")),
  paragraph: dynamic(
    () => import("@/components/pages/article/EditorContent/renderers/EditorContentParagraph")
  ),
  quote: dynamic(() => import("@/components/pages/article/EditorContent/renderers/EditorContentQuote")),
  table: dynamic(() => import("@/components/pages/article/EditorContent/renderers/EditorContentTable")),
  raw: dynamic(() => import("@/components/pages/article/EditorContent/renderers/EditorContentRaw")),
  prosCons: dynamic(
    () => import("@/components/pages/article/EditorContent/renderers/EditorContentComparisonBlock")
  ),
  prosConsList: dynamic(
    () => import("@/components/pages/article/EditorContent/renderers/EditorContentProsAndConsList")
  ),
  checklist: dynamic(
    () => import("@/components/pages/article/EditorContent/renderers/EditorContentChecklist")
  ),
  warning: dynamic(() => import("@/components/pages/article/EditorContent/renderers/EditorContentWarning")),
  buttons: dynamic(() => import("@/components/pages/article/EditorContent/renderers/EditorContentButtons")),
};
