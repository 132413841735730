import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "@/store/store";

const initialState = {
  initialized: false,
  isLoading: false,
  storageChecked: false,
};

export const chatwootSlice = createSlice({
  name: "chatwoot",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    ready: (state) => {
      state.isLoading = false;
      state.initialized = true;
    },
  },
});

// Action creators are generated for each case reducer function
export const { ready, setLoading } = chatwootSlice.actions;

export default chatwootSlice.reducer;

// Selectors
export const chatwootInitSelector = (state: RootState) => state.chatwoot.initialized;
export const chatwootLoadingSelector = (state: RootState) => state.chatwoot.isLoading;
