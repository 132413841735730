"use client";
import cn from "classnames";
import { upperFirst } from "lodash";
import { useEffect, useRef, useState } from "react";
import styles from "./AccordionItem.module.scss";
import { EditorContent } from "../pages/article/EditorContent";
import cs from "@/styles/modules/card.module.scss";
import { SpriteIcon } from "@/components/UI/SpriteIcon";
import IconAngleDown from "@/public/img/icons/svg/angle-down.svg";

type AccordionItemWithEditorContentProps = {
  heading: string;
  content: string;
  active?: boolean;
  onToggle?: (value: boolean) => boolean;
  className?: string;
  background?: boolean;
  type?: string;
  headingSize?: "default" | "big";
};

//TODO: temporary component - copy AccordionItem
export const AccordionItemWithEditorContent = ({
  heading,
  content,
  active,
  onToggle,
  className,
  background,
  type = "default",
  headingSize,
}: AccordionItemWithEditorContentProps) => {
  const contentWrapper = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState(!!active);

  const parsedContent = JSON.parse(content);

  const openItem = () => {
    if (!contentWrapper.current) return;
    contentWrapper.current.style.maxHeight = contentWrapper.current.scrollHeight + "px";
    setIsOpen(true);
  };

  const closeItem = () => {
    if (!contentWrapper.current) return;
    contentWrapper.current.style.maxHeight = "0";
    setIsOpen(false);
  };

  const toggleItem = () => {
    if (!isOpen) openItem();
    else closeItem();
  };

  useEffect(() => {
    isOpen && openItem();
    onToggle && onToggle(isOpen);
  }, [isOpen, onToggle]);

  return (
    <div
      className={cn(
        styles.accordionItem,
        {
          [cs.cardGlassHover]: !background,
          [cs.cardDefaultHover]: !!background,
          [styles.open]: isOpen,
          [styles.withBg]: background,
          [styles.headingBig]: headingSize === "big",
        },
        styles[`type${upperFirst(type)}`],
        className
      )}
    >
      <div className={cn(styles.headWrapper)} onClick={toggleItem}>
        <h3 className={styles.heading}>{heading}</h3>
        <button aria-label="expand / shrink">
          <SpriteIcon icon={IconAngleDown} />
        </button>
      </div>
      <div ref={contentWrapper} className={styles.contentWrapper}>
        <div className={cn(styles.content, styles.editor)}>
          <EditorContent data={parsedContent} />
        </div>
      </div>
    </div>
  );
};
